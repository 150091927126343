@use "./scss/variables" as vars;

//Bootstrap overrides

$theme-colors: (
  "primary": vars.$color-review-blue,
);

$body-bg: #ffffff;
$border-radius: 0.3rem;
$container-max-widths: (
  sm: 570px,
  md: 100%,
  lg: 100%,
  xl: 100%,
  xxl: 100%,
) !default;

@import "~bootstrap/scss/bootstrap.scss";
@import "./scss/utilities";
@import "./scss/typography";
@import "./scss/buttons";

@font-face {
  font-family: "Montserrat-Bold_2", "Montserrat", sans-serif;
  src: local("/src/assets/fonts/Montserrat-Bold_2/Montserrat-Bold_2.ttf")
    format("truetype");
  // src: url('/src/assets/fonts/Montserrat-Bold_2/Montserrat-Bold_2.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url("../src/assets/fonts/Helvetica.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "HelveticaBold";
  src: local("Helvetica"), url("../src/assets/fonts/Helvetica-Bold.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "ESquare";
  src: local("ESquare"), url("../src/assets/fonts/E-SQUARE.woff");
  font-style: bold;
  font-weight: 700;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto:wght@400;700&family=Alegreya:wght@400;700&family=Anton&family=Archivo+Black&family=Arimo:wght@400;700&family=Bebas+Neue&family=Cormorant+Garamond:wght@400;700&family=Homemade+Apple&family=Josefin+Sans:wght@400;700&family=Libre+Baskerville:wght@400;700&family=Montserrat:wght@400;700&family=Old+Standard+TT:wght@400;700&family=Oswald:wght@400;700&family=Playfair+Display:wght@400;700&family=Roboto+Slab:wght@400;700&family=Rock+Salt&family=Shadows+Into+Light&family=Sigmar+One&family=Source+Code+Pro:wght@400;700&family=Syncopate:wght@400;700&display=swap");



// @font-face {
//   font-family: 'Montserrat2', Montserrat, sans-serif;
//   src: url('/src/assets/fonts/Montserrat-Bold_2.ttf') format('truetype');
//   src: url('/src/assets/fonts/Montserrat-Bold_2.woff') format('woff');
//   font-style: normal;
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'Montserrat2', Montserrat, sans-serif;
//   src: url('/src/assets/fonts/Montserrat-Bold_2.ttf') format('truetype');
//   src: url('/src/assets/fonts/Montserrat-Bold_2.woff') format('woff');
//   font-style: bold;
//   font-weight: 700;
// }

// @font-face {
//   font-family: 'Montserrat2', Montserrat, sans-serif;
//   src: url('/src/assets/fonts/Montserrat-Bold_2.ttf') format('truetype');
//   src: url('/src/assets/fonts/Montserrat-Bold_2.woff') format('woff');
//   font-style: italic;
//   font-weight: 700;
// }

:root {
  font-size: 62.5%;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: vars.$color-dark;
  font-family: vars.$font-body, sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: vars.$font-size-xs;
  background-color: vars.$color-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:link,
a:visited {
  color: vars.$color-review-blue;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.page-container {
  padding-top: 10rem;
}

.review-swiper {
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // border: 1px solid blue;
  .swiper-slide {
    background-color: vars.$color-light-gray-2;
    width: auto;
    opacity: 0.3;

    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 0.5;
    }

    &.swiper-slide-active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }
}

img {
  &.circle {
    @media screen and (min-width: 768px) {
      border-radius: 50% !important;
    }
  }
}

// Custom image gallery styles =======================================
.review-gallery {
  .image-gallery-slides {
    // border-radius: vars.$radius-m;
  }

  .image-gallery-thumbnail {
    margin-top: 1.5rem;
    // border-radius: calc(vars.$radius-s);
    width: 15%;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.5;
    border: 0;

    & + .image-gallery-thumbnail {
      margin-left: 2%;
    }

    &:hover {
      border: 0;
      opacity: 1;
    }
  }

  .image-gallery-thumbnail-inner {
    overflow: hidden;
    border-radius: vars.$radius-m;
  }

  .image-gallery-thumbnail.active {
    border: 0;
    opacity: 1;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-size: 3.5rem;
  }

  .image-gallery-left-nav {
    padding: 5rem 1rem 5rem 2rem;
  }

  .image-gallery-right-nav {
    padding: 5rem 2rem 5rem 1rem;
  }

  .image-gallery-thumbnails {
    display: none;
    .image-gallery-thumbnails-container {
      text-align: left;
    }
  }
  .image-gallery-bullets {
    display: none;
    .image-gallery-bullet {
      opacity: 0.5;
      box-shadow: none;

      &.active {
        opacity: 0.8;
      }
    }
  }
  .image-gallery-icon {
    filter: none;
    color: vars.$color-light;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      color: vars.$color-light;
      opacity: 1;
    }
  }

  @media screen and (min-width: 576px) {
    .image-gallery-bullets,
    .image-gallery-thumbnails {
      display: block;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: vars.$color-light !important;
  background-size: contain;
  background-position: center;
}

// .swiper-button-next::after,
// .swiper-button-prev::after {
//   display: none;
// }

// Custom datepicker flatpicker styles ======================================
