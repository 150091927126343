// Import Bootstrap SCSS
@import "bootstrap/scss/bootstrap";

// Personalizzazione del componente

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

// Stile del dropdown custom
.custom_dropdown {
  position: relative;
  width: 50%;
  margin: auto;
  text-align: left;

  .dropdown_toggle {
    padding: 10px 15px;
    font-size: 1.2rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    transition: background 0.2s;

    &:hover {
      background: #f8f9fa;
    }
  }

  .dropdown_menu {
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    border: 2px solid #ddd;
    border-radius: 8px;
    background: white;
    padding: 0;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    // border: 1px solid red;
  }

  .dropdown_item {
    padding: 10px 15px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.2s;
    &:hover {
      background: #e9ecef;
    }
  }
}

// Anteprima del testo con il font selezionato
.preview_box {
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.preview_text {
  font-size: 24px;
  font-weight: 500;
}
